/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React from 'react';
import './index.scss';
import {
  TapformFormBackIcon,
  TapformFormPhoneIcon,
} from '../../assets/images/svg';
import APIService from '../../services/api/index.api';
import { InputType } from '../../services/api/types/answers.response.d';

interface TapformFormProps {
  logoId: number;
  question: string;
  answers: {
    id: number;
    text: string;
    imageId: number;
    order: number;
  }[];
  phoneTextOne: string;
  phoneTextTwo: string;
  buttonText: string;
  brandColor: string;
  inputType?: InputType;
}

function TapformForm({
  logoId,
  question,
  answers,
  phoneTextOne,
  phoneTextTwo,
  buttonText,
  brandColor,
  inputType = InputType.SINGLE_SELECT,
}: TapformFormProps) {
  return (
    <div
      className="tapform-form-component-container"
      style={
        {
          '--brand-color': `${brandColor}`,
        } as React.CSSProperties
      }
    >
      <div className="tapform-form-component-progress">
        <div
          className="tapform-form-component-progress-bar"
          style={{ backgroundColor: brandColor }}
        />
      </div>
      <div className="tapform-form-component-header">
        <div className="tapform-form-component-header-back">
          <TapformFormBackIcon className="tapform-form-component-header-back-icon" />
        </div>
        <img
          className="tapform-form-component-header-logo"
          src={APIService.imageService.createImageUrl(logoId)}
        />
        <div className="tapform-form-component-header-phone">
          <div className="tapform-form-component-header-phone-left">
            <span className="tapform-form-component-header-phone-left-one">
              {phoneTextOne}
            </span>
            <span className="tapform-form-component-header-phone-left-two">
              {phoneTextTwo}
            </span>
          </div>
          <div className="tapform-form-component-header-phone-action">
            <TapformFormPhoneIcon className="tapform-form-component-header-phone-action-icon" />
          </div>
        </div>
      </div>
      <span className="tapform-form-component-question">{question}</span>
      <div className={`tapform-form-component-answers ${inputType}`}>
        <div className={`tapform-form-component-answers-holder ${inputType}`}>
          {answers.map((ans) => (
            <div
              key={`ans_${ans.id}`}
              className={`tapform-form-component-answer ${inputType}`}
            >
              {inputType === InputType.SINGLE_SELECT ? (
                <img
                  className="tapform-form-component-answer-image"
                  src={APIService.imageService.createImageUrl(ans.imageId)}
                />
              ) : null}
              <div
                className={`tapform-form-component-answer-text ${inputType}`}
              >
                {inputType === InputType.SINGLE_SELECT_NO_ICON ? (
                  <span className={`tapform-form-component-answer-dot`}>
                    <span
                      className={`tapform-form-component-answer-dot-inner`}
                    />
                  </span>
                ) : null}
                {ans.text}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="tapform-form-component-submit-holder">
        <button
          className="tapform-form-component-submit"
          style={{ backgroundColor: brandColor }}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

export default TapformForm;
