// Generated by https://quicktype.io

export enum InputType {
  SINGLE_SELECT = 'single-select',
  SINGLE_SELECT_NO_ICON = 'single-select-no-icon',
  MULTIPLE_SELECT = 'multiple-select',
  MULTIPLE_SELECT_NO_ICON = 'multiple-select-no-icon',
  PHONE_INPUT = 'phone-input',
  TEXT_INPUT = 'text-input',
  EMAIL_INPUT = 'email-input',
}

export interface AnswersGetUserLeadAnswersResponse {
  data: {
    answers: {
      id: number;
      createdAt: string;
      modifiedAt: string;
      deletedAt: string;
      textAnswer: string;
      input: {
        id: number;
        createdAt: string;
        modifiedAt: string;
        deletedAt: string;
        question: string;
        order: number;
        inputType: InputType;
      };
      options: {
        option: {
          id: number;
          createdAt: string;
          modifiedAt: string;
          deletedAt: string;
          order: number;
          value: string;
          price: {
            id: number;
            createdAt: string;
            modifiedAt: string;
            deletedAt: string;
            minPriceRule: number;
            title: string;
            description: string;
            image: {
              id: number;
              createdAt: string;
              modifiedAt: string;
              deletedAt: null;
              filename: string;
              size: number;
            } | null;
          };
        };
      }[];
    }[];
  };
}
