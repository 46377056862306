import Timezone from 'timezone-enum';
import { ApiBaseService } from './base.api';
import {
  SettingsGetUserSettingsResponse,
  SettingsUpdateUserSettingsResponse,
} from './types/settings.response';
import { message } from 'antd';

export class ApiSettingsService extends ApiBaseService {
  static async getUserFormSettings(token: string, formId: number) {
    const url = new URL(
      `${ApiBaseService.apiUrl}/api/settings?formId=${formId}`,
    );
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      message.error(
        'Someting went wrong! Please contact support: matej@tapform.io',
      );
      return null;
    }
    return (await response.json()) as SettingsGetUserSettingsResponse;
  }

  static async updateUserFormSettings(
    token: string,
    formId: number,
    toUpdate: {
      emailNotificationsActive?: boolean;
      weeklyReportActive?: boolean;
      notificationEmail?: string;
      userTimezone?: Timezone;
      notificationEmails?: string[];
      origins?: string[];
      ga4ID?: string | null;
      calendlyUrl?: string | null;
      calendlyEnabled?: boolean;
      autoConsent?: boolean;
      webHooks?: string[];
      facebookPixelId?: string | null;
      collectUTM?: boolean;
      whatConvertsProfileId?: string | null;
      googleAdsTagId?: string | null;
      googleAdsDestinationId?: string | null;
      tikTokPixelId?: string | null;
      linkedInPartnerId?: string | null;
      linkedInConversionId?: string | null;
      googleTagManagerId?: string | null;
      googleTagManagerConversionEvent?: string | null;
    },
  ) {
    const url = new URL(`${ApiBaseService.apiUrl}/api/settings/update`);
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formId,
        ...toUpdate,
      }),
    });
    if (response.status === 400) {
      message.error(
        'Please check that origins are correct! (Correct format: https://example.com/',
      );
      return null;
    }
    if (response.status !== 200) {
      message.error(
        'Someting went wrong! Please contact support: matej@tapform.io',
      );
      return null;
    }
    return (await response.json()) as SettingsUpdateUserSettingsResponse;
  }
}
