/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useUserState } from '../../store/selectors';
import { Popup } from '../Popup';
import { actionCloseOpenIntegrationWizard } from '../../store/actions/user.actions';
import {
  BackIcon,
  CheckmarkIcon,
  IntegrationHelpGraphics,
  XIcon,
} from '../../assets/images/svg';
import IndicatorLabel from '../IndicatorLabel';
import BannerImage from './bannerRight.webp';
import WordpressIcon from './icons/wordpress.png';
import WooLogo from './icons/woologo.png';
import Universal from './icons/code.png';
import GTAGLogo from './icons/gtag.jpg';
import Button from '../Button';
import { openCalendly } from '../../constants/functions';
import { AnimatePresence, motion } from 'framer-motion';
import { SIMPLE_FRAMER_TRANSITION } from '../../constants/framer';
import { CopyBlock, dracula } from 'react-code-blocks';
import { SettingsGetUserSettingsResponse } from '../../services/api/types/settings.response';
import APIService from '../../services/api/index.api';
import {
  TAPFORM_DEVELOPER_GUIDE_URL,
  TAPFORM_OFFICIAL_WORDPRESS_PLUGIN_URL,
} from '../../constants/links';

interface Integration {
  id: string;
  name: string;
  desc: string;
  icon: string;
  displayCodeSnippet: boolean;
  displayCodeSnippetHeader: boolean;
  steps: { type: 'h' | 'p' | 'a' | 'c'; content: string; href?: string }[];
}

export default function IntegrationWizard() {
  const { integrationWizardOpened, token, defaultForm } =
    useSelector(useUserState);
  const dispatch = useDispatch();
  const [selectedIntegration, setSelectedIntegration] =
    React.useState<Integration | null>(null);
  const animValues = React.useRef<{ x: number }>({ x: 0 });
  const [settingsData, setSettingsData] =
    React.useState<SettingsGetUserSettingsResponse | null>(null);
  const [integrated, setIntegrated] = React.useState<boolean>(false);

  const loadSettings = React.useCallback(async () => {
    if (token && defaultForm) {
      const response = await APIService.settingsService.getUserFormSettings(
        token,
        defaultForm,
      );
      if (response !== null) {
        setSettingsData(response);
      }
    }
  }, [token, defaultForm]);

  React.useEffect(() => {
    if (token && defaultForm) {
      (async () => {
        const integrated = await APIService.statsService.checkFormIntegrated(
          token,
          defaultForm,
        );
        if (integrated?.data?.integrated === false) {
          setIntegrated(false);
        } else {
          setIntegrated(true);
        }
      })();
    }
  }, [token, defaultForm]);

  const INTEGRATIONS: Integration[] = [
    {
      id: 'wordpress',
      name: 'Wordpress',
      desc: 'Wordpress plugin',
      icon: WordpressIcon,
      displayCodeSnippet: false,
      displayCodeSnippetHeader: false,
      steps: [
        {
          type: 'h',
          content: 'How to integrate Tapform to your Wordpress website?',
        },
        {
          type: 'a',
          content:
            '1. Download or directly install official Tapform Wordpress plugin',
          href: TAPFORM_OFFICIAL_WORDPRESS_PLUGIN_URL,
        },
        {
          type: 'p',
          content: '2. Login to your Wordpress admin dashboard',
        },
        {
          type: 'p',
          content:
            '3. In the left menu panel click on "Plugins" and then install downloaded plugin .zip',
        },
        {
          type: 'p',
          content:
            '4. Once activated, click on the left menu panel on "Tapform"',
        },
        {
          type: 'p',
          content:
            '5. Paste your Tapform Form Identifier (below) and click on "Save changes"',
        },
        {
          type: 'h',
          content: 'Your Tapform Form Identifier',
        },
        {
          type: 'c',
          content: settingsData?.data?.settings.formIdentifier || '',
        },
      ],
    },
    {
      id: 'woocommerce',
      name: 'WooCommerce',
      desc: 'Wordpress plugin',
      icon: WooLogo,
      displayCodeSnippet: false,
      displayCodeSnippetHeader: false,
      steps: [
        {
          type: 'h',
          content: 'How to integrate Tapform to your WooCommerce website?',
        },
        {
          type: 'a',
          content:
            '1. Download or directly install official Tapform Wordpress plugin',
          href: TAPFORM_OFFICIAL_WORDPRESS_PLUGIN_URL,
        },
        {
          type: 'p',
          content: '2. Login to your WooCommerce/Wordpress admin dashboard',
        },
        {
          type: 'p',
          content:
            '3. In the left menu panel click on "Plugins" and then install downloaded plugin .zip',
        },
        {
          type: 'p',
          content:
            '4. Once activated, click on the left menu panel on "Tapform"',
        },
        {
          type: 'p',
          content:
            '5. Paste your Tapform Form Identifier (below) and click on "Save changes"',
        },
        {
          type: 'h',
          content: 'Your Tapform Form Identifier',
        },
        {
          type: 'c',
          content: settingsData?.data?.settings.formIdentifier || '',
        },
      ],
    },
    {
      id: 'x',
      name: 'Google Tag Manager',
      desc: 'Install via GTAG',
      icon: GTAGLogo,
      displayCodeSnippet: false,
      displayCodeSnippetHeader: true,
      steps: [
        {
          type: 'h',
          content:
            'How to integrate Tapform to any website using Google Tag Manager?',
        },
        {
          type: 'p',
          content: '1. Copy your Tapform code snippet (above)',
        },
        {
          type: 'p',
          content:
            '2. Login to your Google Tag Manager workspace for your website',
        },
        {
          type: 'p',
          content: '3. In the left menu panel click on "Tags"',
        },
        {
          type: 'p',
          content:
            '4. Create new tag by clicking on "New" above the list of all of your tags',
        },
        {
          type: 'p',
          content:
            '5. Select "Custom HTML" for "Tag configuration" and pase your code snippet',
        },
        {
          type: 'p',
          content: '6. Select "All Pages" for your tag "Triggering"',
        },
        {
          type: 'p',
          content:
            '7. Name your tag and click on "Save" and then publish and submit your changes',
        },
      ],
    },
    {
      id: 'x',
      name: 'HTML Code',
      desc: 'Universal solution',
      icon: Universal,
      displayCodeSnippet: true,
      displayCodeSnippetHeader: false,
      steps: [
        {
          type: 'h',
          content: 'How to integrate Tapform to any website?',
        },
        {
          type: 'p',
          content: '1. Copy your Tapform code snippet (above)',
        },
        {
          type: 'p',
          content:
            '2. Paste your code snippet inside <body>...</body> near the </body> (before the closing tag on the end)',
        },
        {
          type: 'p',
          content: '3. Save and Tapform is connected!',
        },
        {
          type: 'p',
          content:
            'You can read our detailed documentation on the link below or you can always feel free to reach out to us, book a call where we can help you integrate Tapform to your existing website. There',
        },
        {
          type: 'a',
          content: 'Integration help documentation and guide',
          href: TAPFORM_DEVELOPER_GUIDE_URL,
        },
      ],
    },
  ];

  React.useEffect(() => {
    if (!integrationWizardOpened) {
      setSelectedIntegration(null);
    } else if (token && defaultForm) {
      loadSettings();
    }
  }, [integrationWizardOpened]);

  return (
    <Popup
      active={integrationWizardOpened}
      onExit={() => {
        dispatch(actionCloseOpenIntegrationWizard());
      }}
    >
      <div className={styles.container}>
        <button
          className={styles.backButton}
          onClick={() => {
            if (!selectedIntegration) {
              dispatch(actionCloseOpenIntegrationWizard());
            } else {
              setSelectedIntegration(null);
            }
          }}
        >
          <BackIcon className={styles.backButtonIcon} />
        </button>
        <AnimatePresence mode="wait">
          {selectedIntegration === null ? (
            <motion.div
              initial={{ opacity: 0, x: animValues.current.x, scale: 1 }}
              animate={{ opacity: 1, x: 0, scale: 1 }}
              exit={{ opacity: 0, x: 500, scale: 1 }}
              transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.5 })}
              className={styles.content}
              key="empty"
              onAnimationComplete={(definition) => {
                if ((definition as any).x === 500) {
                  animValues.current.x = -500;
                } else {
                  animValues.current.x = 0;
                }
              }}
            >
              <div className={styles.header}>
                <div className={styles.headerLeft}>
                  <h2 className={styles.headerTitle}>Integration</h2>
                  <p className={styles.headerDesc}>
                    Connect Tapform with your existing website
                  </p>
                </div>
                <IndicatorLabel color={integrated ? 'blue' : 'red'}>
                  {integrated ? (
                    <>
                      <CheckmarkIcon
                        className={styles.approveIconColor}
                        style={{ marginRight: 8 }}
                      />
                      Integrated
                    </>
                  ) : (
                    <>
                      <XIcon style={{ marginRight: 8 }} />
                      Not integrated
                    </>
                  )}
                </IndicatorLabel>
              </div>
              <div className={styles.banner}>
                <div className={styles.bannerLeft}>
                  <span className={styles.bannerLabel}>CONNECT</span>
                  <h3 className={styles.bannerTitle}>
                    Get more leads with your website integration
                  </h3>
                  <p className={styles.bannerDesc}>
                    Our widgets and popups supercharge conversions and turn
                    visitors into leads effortlessly. Simple setup, amazing
                    results! ✨
                  </p>
                </div>
                <div className={styles.bannerRight}>
                  <img className={styles.bannerRightImage} src={BannerImage} />
                </div>
              </div>
              <div className={styles.integrations}>
                {INTEGRATIONS.map((integration) => (
                  <div
                    className={styles.integration}
                    key={integration.id}
                    onClick={() => {
                      setSelectedIntegration(integration);
                    }}
                  >
                    <div className={styles.integrationLeft}>
                      <img
                        className={styles.integrationIcon}
                        src={integration.icon}
                      />
                      <div className={styles.integrationContent}>
                        <span className={styles.integrationName}>
                          {integration.name}
                        </span>
                        <span className={styles.integrationDesc}>
                          {integration.desc}
                        </span>
                      </div>
                    </div>
                    <button className={styles.integrationButton}>
                      Connect
                    </button>
                  </div>
                ))}
              </div>
              <div className={styles.breaker} />
              <div className={styles.help}>
                <IntegrationHelpGraphics className={styles.helpGraphic} />
                <h3 className={styles.helpTitle}>
                  Do you need help with integration?
                </h3>
                <p className={styles.helpDesc}>
                  Feel free to reach out to us and book a call where we will
                  help you integrate Tapform to your existing website.
                </p>
                <Button
                  onClick={openCalendly}
                  className={styles.helpButton}
                  text="Book a call"
                />
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="selected"
              initial={{ opacity: 0, x: -500, scale: 1 }}
              animate={{ opacity: 1, x: 0, scale: 1 }}
              exit={{ opacity: 0, x: 500, scale: 1 }}
              transition={SIMPLE_FRAMER_TRANSITION({ duration: 0.5 })}
              className={styles.content}
            >
              <div className={styles.header}>
                <div className={styles.headerLeftIntegration}>
                  <img
                    className={styles.integrationIcon}
                    src={selectedIntegration.icon}
                  />
                  <div className={styles.headerLeft}>
                    <h2 className={styles.headerTitle}>
                      {selectedIntegration.name} Integration
                    </h2>
                    <p className={styles.headerDesc}>
                      Connect Tapform with your existing website
                    </p>
                  </div>
                </div>
                {!integrated ? (
                  <IndicatorLabel color="red">
                    <XIcon style={{ marginRight: 8 }} /> Not integrated
                  </IndicatorLabel>
                ) : null}
              </div>
              {(selectedIntegration.displayCodeSnippet ||
                selectedIntegration.displayCodeSnippetHeader) &&
              settingsData?.data?.settings.integrationCode ? (
                <>
                  <CopyBlock
                    language="html"
                    text={
                      selectedIntegration.displayCodeSnippet
                        ? settingsData.data.settings.integrationCode
                        : settingsData.data.settings.integrationCodeHead
                    }
                    wrapLines={true}
                    theme={dracula}
                    codeBlock
                    showLineNumbers={false}
                    className={styles.codeBlock}
                  />
                  <div style={{ height: 32, width: '100%' }} />
                </>
              ) : null}
              <div className={styles.steps}>
                {selectedIntegration.steps.map((step, index) => {
                  switch (step.type) {
                    case 'a':
                      return (
                        <a key={index} href={step.href} target="_blank">
                          {step.content}
                        </a>
                      );
                    case 'p':
                      return <p key={index}>{step.content}</p>;
                    case 'h':
                      return <h3 key={index}>{step.content}</h3>;
                    case 'c':
                      return (
                        <>
                          <CopyBlock
                            key={index}
                            language="text"
                            text={step.content}
                            wrapLines={true}
                            theme={dracula}
                            codeBlock
                            showLineNumbers={false}
                            className={styles.codeBlock}
                          />
                          <div style={{ height: 32, width: '100%' }} />
                        </>
                      );
                  }
                  return null;
                })}
              </div>
              <div className={styles.breaker} />
              <div className={styles.help}>
                <IntegrationHelpGraphics className={styles.helpGraphic} />
                <h3 className={styles.helpTitle}>
                  Do you need help with integration?
                </h3>
                <p className={styles.helpDesc}>
                  Feel free to reach out to us and book a call where we will
                  help you integrate Tapform to your existing website.
                </p>
                <Button
                  onClick={openCalendly}
                  className={styles.helpButton}
                  text="Book a call"
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Popup>
  );
}
