import { StatsSummaryResponse } from '../../services/api/types/stats.response';
import { ReduxActionPayload } from '../actions/types';
import { ActionTypes } from '../actions/types/type.actions.d';
import {
  ActionLoadDefaultFormPayload,
  ActionLoadLogoImagePayload,
  ActionLoadOnboardingOnePayload,
  ActionLoadOnboardingThreePayload,
  ActionLoadOnboardingTwoPayload,
  ActionLoadUserDataPayload,
  ActionRegisterLoginPayload,
  ActionSetCalendlyAuthCode,
  ActionSetDefaultFormPayload,
  ActionStartWaitingScreenPayload,
  ActionUserLoginPayload,
  ActionVerifyChangeEmailPayload,
} from '../actions/types/user';
import { UserState } from './types/user';

const defaultState: UserState = {
  isLoggedIn: false,
  userData: null,
  token: null,
  defaultForm: null,
  form: null,
  forms: null,
  verify: null,
  visitorType: 'guest',
  waitingScreen: null,
  welcomeScreen: false,
  walkthrough: null,
  isAgency: false,
  onboardedWithAI: false,
  formEditOpened: false,
  statsSummary: null,
  integrationWizardOpened: false,
  calendlyAuthCode: null,
};

function userReducer(
  state = defaultState,
  action: ReduxActionPayload,
): UserState {
  switch (action.type) {
    case ActionTypes.USER_LOGIN: {
      const payload = action.payload as ActionUserLoginPayload;
      return {
        ...state,
        isLoggedIn: true,
        token: payload.token,
        visitorType: payload.isOnboarding ? 'onboarding' : 'user',
        verify: null,
        isAgency: payload.type === 'agency',
      };
    }
    case ActionTypes.USER_REGISTER_LOGIN: {
      const { email, resendToken } =
        action.payload as ActionRegisterLoginPayload;
      return {
        ...state,
        isAgency: false,
        verify: {
          email,
          resendToken,
        },
        visitorType: 'verify',
      };
    }
    case ActionTypes.CHANGE_VERIFY_EMAIL: {
      const { email } = action.payload as ActionVerifyChangeEmailPayload;
      if (state.verify) {
        return {
          ...state,
          verify: {
            ...state.verify,
            email,
          },
        };
      }
      return state;
    }
    case ActionTypes.LOAD_USER_DATA: {
      const payload = action.payload as ActionLoadUserDataPayload;
      return {
        ...state,
        userData: payload.user,
        visitorType:
          payload.user.onboardingStep === null ? 'user' : 'onboarding',
      };
    }
    case ActionTypes.USER_LOGOUT: {
      return {
        ...state,
        ...defaultState,
      };
    }
    case ActionTypes.LOAD_DEFAULT_FORM: {
      const payload = action.payload as ActionLoadDefaultFormPayload;
      return {
        ...state,
        defaultForm: payload.formId,
        form: payload.form,
        forms: payload.availableForms,
      };
    }

    // ONBOARDING

    case ActionTypes.LOAD_ONBOARDING_ONE: {
      if (state.userData) {
        const { timezone, firstName, lastName } =
          action.payload as ActionLoadOnboardingOnePayload;
        return {
          ...state,
          userData: {
            ...state.userData,
            timezone,
            firstName,
            lastName,
            onboardingStep: 2,
          },
        };
      }
      return state;
    }

    case ActionTypes.LOAD_ONBOARDING_TWO: {
      if (state.userData) {
        const { phoneNumber } =
          action.payload as ActionLoadOnboardingTwoPayload;
        return {
          ...state,
          userData: {
            ...state.userData,
            phoneNumber,
            onboardingStep: 3,
          },
        };
      }
      return state;
    }

    case ActionTypes.LOAD_ONBOARDING_THREE: {
      if (state.userData) {
        const payload = action.payload as ActionLoadOnboardingThreePayload;
        return {
          ...state,
          userData: {
            ...state.userData,
            onboardingStep: 4,
          },
          waitingScreen: payload.duration,
          onboardedWithAI: payload.onboardedWithAI,
        };
      }
      return state;
    }

    case ActionTypes.LOAD_ONBOARDING_FOUR: {
      if (state.userData) {
        return {
          ...state,
          userData: {
            ...state.userData,
            onboardingStep: null,
          },
          welcomeScreen: true,
          visitorType: 'user',
        };
      }
      return state;
    }

    case ActionTypes.END_WELCOME_SCREEN: {
      if (state.welcomeScreen) {
        return {
          ...state,
          welcomeScreen: false,
          walkthrough: 1,
        };
      }

      return state;
    }

    case ActionTypes.START_WAITING_SCREEN: {
      const { duration } = action.payload as ActionStartWaitingScreenPayload;
      return {
        ...state,
        waitingScreen: duration,
      };
    }

    case ActionTypes.STOP_WAITING_SCREEN: {
      return {
        ...state,
        waitingScreen: null,
      };
    }

    case ActionTypes.LOAD_LOGO_IMAGE: {
      if (state.form) {
        const { image } = action.payload as ActionLoadLogoImagePayload;
        return {
          ...state,
          form: {
            ...state.form,
            logoImage: image,
          },
        };
      }
      return state;
    }

    case ActionTypes.INCREMENT_WALKTHROUGH: {
      if (state.walkthrough === null) {
        return {
          ...state,
          walkthrough: 1,
        };
      } else if (state.walkthrough === 3) {
        return {
          ...state,
          walkthrough: null,
        };
      } else {
        return {
          ...state,
          walkthrough: state.walkthrough + 1,
        };
      }
    }

    case ActionTypes.FINISH_WALKTHROUGH: {
      return {
        ...state,
        walkthrough: null,
      };
    }

    case ActionTypes.SET_DEFAULT_FORM: {
      const payload = action.payload as ActionSetDefaultFormPayload;
      const foundForm = state.forms?.find((f) => f.id === payload.formId);
      if (foundForm) {
        return {
          ...state,
          defaultForm: foundForm.id,
          form: foundForm,
        };
      }
      return state;
    }

    case ActionTypes.OPEN_FORM_EDIT: {
      return {
        ...state,
        formEditOpened: true,
      };
    }

    case ActionTypes.CLOSE_FORM_EDIT: {
      return {
        ...state,
        formEditOpened: false,
      };
    }

    case ActionTypes.LOAD_STATS_SUMMARY: {
      const statsSummary = action.payload as StatsSummaryResponse | null;
      return {
        ...state,
        statsSummary,
      };
    }

    case ActionTypes.OPEN_INTEGRATION_WIZARD: {
      return {
        ...state,
        integrationWizardOpened: true,
      };
    }

    case ActionTypes.CLOSE_INTEGRATION_WIZARD: {
      return {
        ...state,
        integrationWizardOpened: false,
      };
    }

    case ActionTypes.SET_CALENDLY_AUTH_CODE: {
      const payload = action.payload as ActionSetCalendlyAuthCode;
      return {
        ...state,
        calendlyAuthCode: payload.code,
      };
    }

    default:
      return state;
  }
}

export default userReducer;
